import React from 'react';
import VideoContainer from './components/video-container';
import Homepage from './components/homepage';
import MeditationList from './components/meditation-list';
import AudioPlayerWrapper from './components/audio-player/audio-player-wrapper';
import { HashRouter, BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';



const Router: any = window.location.protocol === 'file:' ? HashRouter : BrowserRouter;


function NoMatch(params: RouteComponentProps<any>) {
    return (
        <div>
            <h3>
                No se encontró la página <code>{params.location.pathname}</code>
            </h3>
        </div>
    );
}


export default function App() {
    return (
        <Router basename="/">
            <VideoContainer />
            <Switch>
                <Route path="/" exact component={Homepage} />
                <Route path="/index.html" component={Homepage} />
                <Route path="/meditar" exact component={MeditationList} />
                <Route path="/meditar/:audio" component={AudioPlayerWrapper} />
                <Route component={NoMatch} />
            </Switch>
        </Router>

  );
}
