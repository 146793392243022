import React from 'react';

export interface ProgressCircleProps {
    currentTime: number,
    duration: number
}

export default function ProgressCircle(props: ProgressCircleProps){
    let percentage = Math.round((props.currentTime / props.duration) * 100);
    if (Number.isNaN(percentage)) {
        percentage = 0;
    }
    const strokeWidth = 30;
    const radius = 90;
    const r = radius - strokeWidth / 2;
    const width = radius * 2;
    const height = radius * 2;
    const viewBox = `0 0 ${width} ${height}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * percentage / 100;

        return (
            <svg
                className="CircularProgress"
                width={radius * 2}
                height={radius * 2}
                viewBox={viewBox}>
                <circle
                    className="CircularProgress-Bg"
                    cx={radius}
                    cy={radius}
                    r={r}
                    strokeWidth={`${strokeWidth}px`} />
                <circle
                    className="CircularProgress-Fg"
                    cx={radius}
                    cy={radius}
                    r={r}
                    strokeWidth={`${strokeWidth}px`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />

            </svg>
        );

}
