import React from 'react';
import MainContainer from './main-container';
import MeditationListItem from './meditation-list-item';
import meditations from '../data/meditation-data'

export default function MeditationList() {
const meditacionesdata = meditations();
    return (
        <MainContainer>
            <div className="meditation-list-container">
                <p>Elige una meditación:</p>
                <div>
                {meditacionesdata.map(meditation => (
                    <MeditationListItem key={meditation.id}
                                        title={meditation.title}
                                        img={meditation.id}
                                        duration={meditation.duration}
                                        audio={meditation.id} />
                ))}
                </div>
            </div>
        </MainContainer>
    );
}

