
export default function isCordova()  {
    if(document.location.protocol !== "http:" && document.location.protocol !== "https:"){
        return true;
    }
    return false;

}





// Reference: https://ourcodeworld.com/articles/read/390/how-to-know-if-your-application-is-running-in-cordova-or-a-web-browser