import { useState } from 'react';
import alib from '../utils/cordova_alib';
import isCordova from '../utils/is-cordova';

// This is intended to be used only when the app is running in Cordova

export default function useDownloadFile(audio: any) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [doesFileExist, setDoesFileExist] = useState(false);

    doesExist();
    function doesExist() {
        if (isCordova()) {
            const filename = alib.getFilePath(audio);
        alib.doesExist(filename, function(err: any, value: any) {
            if (err) {
                console.error('Could not tell if ', filename, ' is downloaded', err);
                return;
            }
            setDoesFileExist(value);

        });
        }
    }


    function handleClick(e: any, audio: any) {
        if (isCordova() && !doesFileExist) {
            e.preventDefault();
            const downloadUrl = 'https://yoguin.com/audio/aprendiendo-0'+audio+'.mp3';
            setIsDownloading(true);
            alib.downloadFile(downloadUrl, function(err: any) {
                if (err) {
                    console.error('Could not download ', downloadUrl, err);
                    return;
                }
                console.log('the file was downloaded successfully!');
                setIsDownloading(false);
                doesExist();
            });
        }
    }



    return {
        doesFileExist,
        isDownloading,
        handleClick,
    }
};

