export default function meditations() {
    return(

   [
    {
        id: 1,
        title: 'Enfoque en la Respiración',
        duration: '10:00',
    },
    {
        title: 'Concéntrate con Notas Mentales',
        duration: '10:00',
        id: 2
    },
    {
        title: 'Escanea tu Cuerpo',
        duration: '10:00',
        id: 3
    },
    {
        title: 'Salir de Piloto Automático',
        duration: '11:00',
        id: 4
    },
    {
        title: 'Haz una Pausa',
        duration: '11:00',
        id: 5
    },
    {
        title: 'El Valor de la Paciencia',
        duration: '10:00',
        id: 6
    },
        {
        title: 'La Consciencia',
        duration: '11:00',
        id: 7
    }
    ]
)
}