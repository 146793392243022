import React from 'react';
import MainContainer from '../main-container';
import {  Redirect } from 'react-router-dom';
import meditations from '../../data/meditation-data';
import Play from './play';
import Pause from './pause';
import ProgressCircle from './progress-circle';
import useAudioPlayer from './useAudioPlayer';
import Modal from "../modal";
import useModal from '../useModal';
import TimeFormatter from '../../utils/time-formatter';
import isCordova from '../../utils/is-cordova';
import getFilename from '../../utils/getFilename'


export default function AudioPlayerWrapper(props: any) {
    const { currentTime, duration, playing, setPlaying } = useAudioPlayer();
    const data = meditations();
    const currentMeditationInfo = data[props.match.params.audio - 1];
    const {isShowing, toggleModal} = useModal();
    const audioSource = isCordova() ? getFilename(currentMeditationInfo.id) : '/audio/'+currentMeditationInfo.id+'.mp3'


    if (!currentMeditationInfo) {
        return <Redirect to="/meditar" />
    }

    function handleClickCloseBtn() {
        toggleModal();
        setPlaying(false);
    }


    return (
        <MainContainer>

            <div className="audio-player-container">
                <audio id="audio">
                    <source src={audioSource} />
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <button onClick={handleClickCloseBtn}
                        className="close-btn-audio-player">
                    <span className="fa-stack">
                    <i className="fal fa-circle fa-stack-2x"/>
                    <i className="far fa-times fa-stack-1x"/>
                  </span>
                </button>


                <Modal
                    isShowing={isShowing}
                    hide={toggleModal}
                />



                <div className="meditation-audio-title">
                    <h4>{currentMeditationInfo.title}</h4>
                </div>
                <div className="audio-control-btn-container">
                <ProgressCircle currentTime={currentTime} duration={duration} />
                {playing ?
                    <Pause handleClick={() => setPlaying(false)} /> :
                    <Play handleClick={() => setPlaying(true)} />
                }
                </div>
                <p>{TimeFormatter(currentTime)} / {TimeFormatter(duration)} </p>
            </div>
        </MainContainer>
    );
}
