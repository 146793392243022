import { useState, useEffect } from 'react';

function useAudioPlayer() {
    const [duration, setDuration] = useState();
    const [currentTime, setCurrentTime] = useState();
    const [playing, setPlaying] = useState(false);

    useEffect(() => {

        const audio = document.getElementById("audio");
        if (!(audio instanceof HTMLMediaElement)) {
            console.error('No audio file found');
            return;
        }

        // state setters wrappers

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurrentTime(audio.currentTime);
        };

        const setAudioTime = () => setCurrentTime(audio.currentTime);

        // DOM listeners: update React state on DOM events
        audio.addEventListener("loadeddata", setAudioData);

        audio.addEventListener("timeupdate", setAudioTime);

        // React state listeners: update DOM on React state changes
        playing ? audio.play() : audio.pause();


        // effect cleanup
        return () => {
            audio.removeEventListener("loadeddata", setAudioData);
            audio.removeEventListener("timeupdate", setAudioTime);
        }
    }, [playing, currentTime] );

    return {
        currentTime,
        duration,
        playing,
        setPlaying,
    }
}

export default useAudioPlayer;

// https://codesandbox.io/s/5wwj02qy7k?from-embed