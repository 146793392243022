import React from 'react';
import logo from '../media/img/logo.png';
import { Link } from 'react-router-dom';
import SocialIcons from './social-icons';

export default function Homepage() {
    return (
        <div className="homepage">
            <div className="homepage-title">
                <img src={logo} alt="yoguin logo lotus"/>
                <h1>Yoguin</h1>
                <hr className="homepage-hr"/>
                <h5>App de Meditación Guiada</h5>
            </div>
            <div className="homepage-btn-container">
                <Link className="btn btn-lg btn-primary" to="/meditar">Entrar</Link>
            </div>
            <SocialIcons/>
        </div>
    );
}

