import React from 'react';



export default function MainContainer(props: any) {


    return (

        <div className="main-container">

            <div>
                {props.children}
            </div>
        </div>


    );
}

