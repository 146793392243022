import React from 'react';
import ReactDOM from 'react-dom';
import {  Link } from 'react-router-dom';



const Modal = ({ isShowing, hide }: any) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <div className="modal-overlay"/>
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal-box">
                <div className="modal-inner">
                    <div className="modal-header">
                        <h5>Terminar Sesión</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div className="modal-body">
                        <p>
                            ¿Estás seguro de que quieres terminar la meditación ahora?
                        </p>
                        <div className="modal-btn-container">
                            <button className="btn btn-light" onClick={hide}>Cancelar</button>
                            <Link className="btn btn-danger" to="/meditar">Terminar</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>, document.body
) : null;

export default Modal;

// Reference: https://github.com/upmostly/modali/