import React from 'react';

export default function Pause(props: any) {
    const { handleClick } = props;

    return (
            <button onClick={() => handleClick()}>
                    <i className="fas fa-pause"/>
            </button>
    );
}