import React from 'react';

export default function Play(props: any) {
    const { handleClick } = props;

    return (
            <button onClick={() => handleClick()}>
                    <i className="fas fa-play"/>
            </button>
    );
}