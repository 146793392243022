import React from 'react';
import badge from '../media/img/es-419_badge_web_generic.png';
import isCordova from '../utils/is-cordova';


export default function SocialIcons() {

    function androidIcon () {
        if (!isCordova()) {
            return (
                <a rel="noopener noreferrer" target="_blank"
                   href='https://play.google.com/store/apps/details?id=com.yoguin'>
                    <img alt='Disponible en Google Play' src={badge} width="250"/>
                </a>
            )
        }
    }
    return (
        <div className="social-icons">
            {androidIcon()}
            <div>
                <a href="https://www.facebook.com/yoguinapp/" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack" key="facebook">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="fab fa-facebook-f fa-stack-1x"/>
              </span>
                </a>
                <a href="https://twitter.com/yoguinapp" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="fab fa-twitter fa-stack-1x"/>
              </span>
                </a>
                <a href="https://www.instagram.com/yoguinapp/" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="fab fa-instagram fa-stack-1x"/>
              </span>
                </a>
                <a href="https://es.pinterest.com/yoguinapp/" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="fab fa-pinterest fa-stack-1x"/>
              </span>
                </a>
                <a href="http://yoguin.tumblr.com/" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="fab fa-tumblr fa-stack-1x"/>
              </span>
                </a>
                <a href="mailto:hola@yoguin.com" target="_blank" rel="noopener noreferrer">
              <span className="fa-stack">
                <i className="fal fa-square fa-stack-2x"/>
                <i className="far fa-envelope fa-stack-1x"/>
              </span>
                </a>
            </div>
        </div>

    )
}