import React from 'react';
import { Link } from 'react-router-dom';
import useDownloadFile from './useDownloadFile' // TODO: Only to be used when the app is running in Cordova
import isCordova from '../utils/is-cordova';

interface MeditationListItemElements {
    title: string,
    img: number,
    duration: string,
    audio: number

}

function MeditationListItem(prop: MeditationListItemElements) {
    const {doesFileExist, isDownloading, handleClick} = useDownloadFile(prop.audio);
    if (isCordova()) {
        let actionIcon = ' fa-arrow-circle-down';
        if (doesFileExist) {
            return (
                <Link
                    to={'/meditar/' + prop.audio} className="meditation-list-item"
                    style={{backgroundImage: `url('img/` + prop.img + `.jpg')`}}
                    onClick={(e) => handleClick(e, prop.audio)}
                >
                    <small className="meditation-item-duration">{prop.duration.substring(0, 2)} min</small>
                    <i className={"action-icon fad fa-3x fa-play-circle"}/>
                    <h6 className="meditation-title">{prop.title}</h6>
                </Link>
            )
        }
        if (isDownloading) actionIcon = 'fad fa-spinner fa-pulse';
        if (!doesFileExist && !isDownloading) actionIcon = 'fa-arrow-circle-down';

        return (
            <Link
                to={'/meditar/' + prop.audio} className="meditation-list-item"
                style={{backgroundImage: `url('img/` + prop.img + `.jpg')`}}
                onClick={(e) => handleClick(e, prop.audio)}
            >
                <small className="meditation-item-duration">{prop.duration.substring(0, 2)} min</small>
                <i className={"action-icon fad fa-3x " + actionIcon}/>
                <h6 className="meditation-title">{prop.title}</h6>
            </Link>
        );
    }
    return (
        <Link
            to={'/meditar/' + prop.audio} className="meditation-list-item"
            style={{backgroundImage: `url('img/` + prop.img + `.jpg')`}}
        >
            <small className="meditation-item-duration">{prop.duration.substring(0, 2)} min</small>
            <i className={"action-icon fad fa-3x fa-play-circle"}/>
            <h6 className="meditation-title">{prop.title}</h6>
        </Link>
    );

}


export default MeditationListItem;