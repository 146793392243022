
exports.getFilePath = function(url) {
    return window.cordova.file.dataDirectory + 'https%3A/yoguin.com/audio/aprendiendo-0'+url+'.mp3';

};


function getDownloadPath(url) {
    return window.cordova.file.dataDirectory + url;

}

exports.doesExist = function(filename, callback) {
  window.resolveLocalFileSystemURL(filename,
    function() { callback(null, true) },
    function() { callback(null, false) }
  );

};


exports.downloadFile = function(url, callback) {
    if (typeof FileTransfer == 'undefined') {
        callback(new Error('Device was not ready...'));
        return;
    }

    var path = getDownloadPath(url);
    console.log('url in downloadFile is: '+url);
    console.log('path in downloadFile is: '+path);

    var fileTransfer = new window.FileTransfer();
        fileTransfer.download(
            url,
            path,
            function (entry) {
                console.log("download complete: " + entry.toURL());
                callback(null, entry.toURL());

            },
            function (error) {
                console.error("download error source " + error.source);
                console.error("download error target " + error.target);
                console.error("download error code" + error.code);
                callback(new Error('download error'));
            }
        );


};



// docs: https://github.com/cfjedimaster/Cordova-Examples/wiki/PhoneGap-Cordova-File-System-FAQ#how-do-i-check-to-see-if-a-file-exists-in-a-directory
// try window.cordova     // eslint-disable-next-line