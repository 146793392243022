import React from 'react';
import videoJpg from '../media/video/video1.jpg'
import videoMp4 from '../media/video/video1.mp4';
import videoWebm from '../media/video/video1.webm';
import videoOgv from '../media/video/video1.ogv';

export default function VideoContainer() {
    return (
        <div className="video-container">
            <video loop muted autoPlay poster={videoJpg} className="fullscreen-video">
                <source src={videoWebm} type="video/webm"/>
                <source src={videoMp4} type="video/mp4"/>
                <source src={videoOgv} type="video/ogg"/>
            </video>
        </div>
);
}

